import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Politica from './pages/politica';
import TermoDeUso from './pages/terms_of_use';
import Sobre from './pages/about';
import Produtos from './pages/produtos';
import Contato from './pages/contato';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/politica" element={<Politica />} />
      <Route path="/terms_of_use" element={<TermoDeUso />} />
      <Route path="/sobre" element={<Sobre />} />
      <Route path="/produtos" element={<Produtos />} />
      <Route path="/contato" element={<Contato />} />
    </Routes>
  );
}

export default App;
