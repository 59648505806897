import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/produtos.css';
import logoQ from '../assets/imgs/logo-fundo-b.png';
import lateral1 from '../assets/imgs/lateral1.png';

function Produtos() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Produtos">
      <header className="Produtos-header">
        <Link to="/">
          <img src={logoQ} className="Produtos-logo" alt="logo" />
        </Link>
        <h1 className="Produtos-title"><span className="Span-produtos">Produtos</span> Desbravalink</h1>
        <img src={lateral1} className="Img-lateral" alt="imagem lateral" />
      </header>

      <main className="Produtos-main">
        <div className="Produtos-content">
          <div >
            <h1 className="H1-destaque">
              Conheça <br /> nossos <br /> produtos
            </h1>
            <p className="P-destaque">Explore nossas soluções digitais criadas para transformar ideias em realidade.</p>
          </div>
          <div className="Card-section">
            <Link to="/contato">
              <button className="Help-button">Precisa de ajuda?</button>
            </Link>
          </div>
        </div>

        <div className="Text-section-centralized">
          <img src={lateral1} className="Img-central" alt="logo" />
        </div>

        <div className="Produtos-unic">
          <div className="Text-section-centralized">
            <h1>Produtos e Serviços</h1>
            <p>Na Desbravalink temos a filosofia da solução real para problemas reais, em cada produto colocamos isso em prática garantindo a melhor entrega.</p>
          </div>
        </div>

        {/* Seção de Cards */}
        <div className="Cards-container">
          <a href="https://fisgados.desbravalink.com.br/" target="_blank" rel="noopener noreferrer" className="Card Fisgados-card">
            <h2>Fisgados</h2>
            <p>App para estudo da Bíblia de forma divertida e interativa. Uma solução que une espiritualidade e ensino.</p>
          </a>
          <a href="https://beeework.desbravalink.com.br/" target="_blank" rel="noopener noreferrer" className="Card Beeework-card">
            <h2>Beeework</h2>
            <p>Nosso sistema de recrutamento e seleção é uma ferramenta que ajuda sua empresa a identificar, avaliar e contratar novos funcionários.</p>
          </a>
          <a href="https://pomodoro.desbravalink.com.br/" target="_blank" rel="noopener noreferrer" className="Card Pomodoro-card">
            <h2>Pomodoro</h2>
            <p>Ferramenta para aumentar o foco com a técnica Pomodoro, simples para te ajudar no estudo e trabalho.</p>
          </a>
          <a href="https://comunic4.desbravalink.com.br/" target="_blank" rel="noopener noreferrer" className="Card Comunic4-card">
            <h2>Comunic4</h2>
            <p>Projeto em andamento que traz a solução para comunicação entre sistemas de forma simples e eficaz.</p>
          </a>
          <a  className="Card Shortlink-card">
            <h2>Shortlink</h2>
            <p>Projeto em andamento que traz o melhor da tecnologia para sua mão e te ajudar a ter links curtos em sua aplicação.</p>
          </a>
        </div>

        <div className="Text-section-centralized">
          <img src={lateral1} className="Img-central" alt="logo" />
        </div>
        
        <div className="Produtos-unic">
          <div className="Text-section-centralized">
            <h1>+ Desbravalink</h1>
            <p>Além de produtos e serviços, a Desbravalink acredita que o conhecimento pode transformar o mundo e tem projetos para fornecer experiência para novos estudantes de tecnologia. Junto com nosso time você pode adquirir a experiência que falta para o mercado de trabalho.</p>
            <Link to="/contato">
              <button className="Help-button">Entre em contato</button>
            </Link>
          </div>
        </div>
      </main>

      <footer className="App-footer">
        <div className="footer-links">
          <p>© 2024 Desbravalink. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}

export default Produtos;
