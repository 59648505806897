import React, {  useEffect  } from 'react';
import { Link } from 'react-router-dom';
import logoQ from '../assets/imgs/logo-fundo-b.png';
import lateral1 from '../assets/imgs/lateral1.png';
import '../styles/politica.css'; // Reutilizando o mesmo CSS da política

function TermsOfUse() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Politica">
      <header className="Politica-header">
        <Link to="/">
          <img src={logoQ} className="Politica-logo" alt="logo" />
        </Link>
        <h1 className="Politica-title"><span className="Span-termo">Termos</span> de Uso</h1>
        <img src={lateral1} className="Img-lateral" alt="imagem lateral" />
      </header>

      <main className="Politica-body">
        {/* Sumário à Esquerda */}
        <nav className="Politica-nav">
          <ul>
            <li><a href="#uso">Uso dos Produtos</a></li>
            <li><a href="#propriedade">Propriedade Intelectual</a></li>
            <li><a href="#conduta">Conduta dos Usuários</a></li>
            <li><a href="#modificacoes">Modificações nos Termos</a></li>
            <li><a href="#responsabilidade">Responsabilidade do Usuário</a></li>
            <li><a href="#contato">Contato</a></li>
          </ul>
        </nav>

        {/* Conteúdo à Direita */}
        <section className="Politica-content">
          <h2 id="uso">1. Uso dos Produtos</h2>
          <p>
            Ao usar nossos produtos, você concorda em utilizá-los de maneira respeitosa, evitando qualquer comportamento abusivo ou ofensivo em suas interações dentro de nossos serviços.
          </p>

          <h2 id="propriedade">2. Propriedade Intelectual</h2>
          <p>
            Todo o conteúdo presente em nossos produtos é de nossa propriedade ou licenciado. É proibido copiar, reproduzir ou distribuir qualquer conteúdo sem autorização prévia.
          </p>

          <h2 id="conduta">3. Conduta dos Usuários</h2>
          <p>
            Você concorda em não usar nossos produtos para fins ilícitos ou prejudiciais, incluindo, mas não se limitando a, violação de direitos autorais, práticas fraudulentas ou comportamento abusivo.
          </p>

          <h2 id="modificacoes">4. Modificações nos Termos</h2>
          <p>
            Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. Quaisquer alterações serão notificadas a você, e recomendamos que você revise os termos periodicamente.
          </p>

          <h2 id="responsabilidade">5. Responsabilidade do Usuário</h2>
          <p>
            Você é responsável por manter a confidencialidade das suas informações de login e por todas as atividades que ocorram sob sua conta.
          </p>

          <h2 id="contato">6. Contato</h2>
          <p>
            Se você tiver dúvidas ou preocupações sobre estes Termos de Uso ou sobre as práticas da Desbravalink, entre em contato conosco:
          </p>
          <p>
            Responsável: Kleyson de Oliveira Gomes <br />
            Telefone: +55 84 996341559 <br />
            E-mail: kleysongomes7@gmail.com
          </p>
          <p>
            Reservamo-nos o direito de atualizar estes Termos de Uso de tempos em tempos. Recomendamos que você revise esta página periodicamente para se manter informado sobre quaisquer alterações.
          </p>
          <p>
            Última atualização: 08 de Junho de 2023
          </p>
        </section>
      </main>
      <footer className="App-footer">
        <div className="footer-links">
          <p>© 2024 Desbravalink. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}

export default TermsOfUse;
