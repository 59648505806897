import React from 'react';

function Sobre() {
  return (
    <div className="page-container">
      <h2>Sobre Nós</h2>
      <p>Conteúdo sobre a empresa aqui...</p>
    </div>
  );
}

export default Sobre;
