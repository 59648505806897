import React, {  useEffect  } from 'react';
import { Link } from 'react-router-dom';
import logoQ from '../assets/imgs/logo-fundo-b.png';
import lateral1 from '../assets/imgs/lateral1.png';
import '../styles/politica.css'; 

function Politica() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="Politica">
      <header className="Politica-header">
        <Link to="/">
          <img src={logoQ} className="Politica-logo" alt="logo" />
        </Link>
        <h1 className="Politica-title"><span className="Span-politica">Política</span> de Privacidade</h1>
        <img src={lateral1} className="Img-lateral" alt="imagem lateral" />
      </header>

      <main className="Politica-body">
        
        {/* Sumário à Esquerda */}
        <nav className="Politica-nav">
          <ul>
            <li><a href="#coleta">Coleta de Informações Pessoais</a></li>
            <li><a href="#uso">Uso das Informações Pessoais</a></li>
            <li><a href="#compartilhamento">Compartilhamento de Informações Pessoais</a></li>
            <li><a href="#seguranca">Segurança das Informações Pessoais</a></li>
            <li><a href="#retencao">Retenção de Informações Pessoais</a></li>
            <li><a href="#direitos">Seus Direitos</a></li>
            <li><a href="#contato">Contato</a></li>
          </ul>
        </nav>

        {/* Conteúdo à Direita */}
        <section className="Politica-content">
          <h2 id="coleta">1. Coleta de Informações Pessoais</h2>
          <h3>1.1 Informações Fornecidas por Você</h3>
          <p>
            Quando você utiliza nossos produtos ou entra em contato conosco, podemos coletar informações pessoais fornecidas por você, como seu nome, endereço de e-mail, número de telefone e outras informações necessárias para atender suas solicitações.
          </p>
          <h3>1.2 Informações Coletadas Automaticamente</h3>
          <p>
            Também podemos coletar informações automaticamente quando você utiliza nossos produtos, como endereço IP, tipo de navegador, dispositivo utilizado, dados de acesso, páginas visitadas e outras informações semelhantes.
          </p>

          <h2 id="uso">2. Uso das Informações Pessoais</h2>
          <h3>2.1 Fornecimento e Melhoria de Produtos</h3>
          <p>
            Utilizamos as informações pessoais coletadas para fornecer nossos produtos, atender suas solicitações, personalizar sua experiência e melhorar a qualidade dos produtos e produtos oferecidos.
          </p>
          <h3>2.2 Comunicações</h3>
          <p>
            Podemos utilizar suas informações de contato para enviar comunicações relacionadas aos nossos produtos, como atualizações, notificações importantes e informações sobre novos recursos ou ofertas promocionais. Caso não deseje receber essas comunicações, você pode optar por não participar a qualquer momento.
          </p>
          <h3>2.3 Cumprimento de Obrigações Legais</h3>
          <p>
            Em determinadas circunstâncias, podemos ser obrigados a divulgar informações pessoais para cumprir obrigações legais ou responder a solicitações legítimas de autoridades governamentais.
          </p>

          <h2 id="compartilhamento">3. Compartilhamento de Informações Pessoais</h2>
          <h3>3.1 Terceiros</h3>
          <p>
            Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer nossos produtos ou quando exigido por lei.
          </p>
          <h3>3.2 Parceiros de Negócios</h3>
          <p>
            Podemos compartilhar informações agregadas ou anônimas com parceiros de negócios para fins de análise, pesquisa de mercado, publicidade ou outros fins legítimos.
          </p>

          <h2 id="seguranca">4. Segurança das Informações Pessoais</h2>
          <p>
            Implementamos medidas de segurança adequadas para proteger as informações pessoais contra acesso não autorizado, uso indevido, divulgação não autorizada ou alteração. No entanto, não podemos garantir a segurança absoluta das informações transmitidas pela Internet ou armazenadas eletronicamente.
          </p>

          <h2 id="retencao">5. Retenção de Informações Pessoais</h2>
          <p>
            Retemos as informações pessoais pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que uma retenção mais longa seja exigida ou permitida por lei.
          </p>

          <h2 id="direitos">6. Seus Direitos</h2>
          <p>
            Você tem o direito de acessar, corrigir ou excluir suas informações pessoais, bem como o direito de revogar seu consentimento para o processamento de suas informações. Para exercer esses direitos ou se tiver alguma dúvida ou preocupação sobre nossa Política de Privacidade, entre em contato conosco por meio das informações fornecidas abaixo.
          </p>

          <h2 id="contato">7. Contato</h2>
          <p>
            Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade ou sobre as práticas de privacidade da Desbravalink, entre em contato conosco:
          </p>
          <p>
            Responsável: Kleyson de Oliveira Gomes <br />
            Telefone: +55 84 996341559 <br />
            E-mail: kleysongomes7@gmail.com
          </p>
          <p>
            Reservamo-nos o direito de atualizar esta Política de Privacidade de tempos em tempos. Recomendamos que você revise esta página periodicamente para se manter informado sobre quaisquer alterações.
          </p>
          <p>
            Última atualização: 08 de Junho de 2023
          </p>
        </section>
      </main>
      <footer className="App-footer">
        <div className="footer-links">
          <p>© 2024 Desbravalink. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}

export default Politica;
