import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import logoQ from '../assets/imgs/logo-fundo-b.png';
import lateral1 from '../assets/imgs/lateral1.png';
import '../styles/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function Home() {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* <a href="#" target="_blank" rel="noopener noreferrer" className="App-button-left">
          <FontAwesomeIcon icon={faDiscord} /> 
          Discord
        </a> */}
      </header>
      <main className="App-body">
        <div className="left-column">
          <img src={logoQ} className="logoQ-image" alt="logo" />
          <h1>Esta é a Desbravalink</h1>
          <div className="content-container">
            <div className="text-container">
              <p className="body-paragraph">
                Somos uma startup de desenvolvimento e inovação, com foco em web e mobile. Temos projetos que vão desde sistemas de recrutamento e seleção até aplicativos móveis de ensino.
              </p>
              {showMore && (
                <>
                  <p className="body-paragraph">
                    Idealizada em 2017, com muito estudo e testes, chegamos a 2023. Juntamos ideias e pessoas especiais e iniciamos nossa jornada de desenvolvimento de tecnologias inovadoras, começando por um sistema de recrutamento e seleção com uma inteligência própria para encontrar o melhor talento para uma empresa.
                  </p>
                  <p className="body-paragraph">
                    Seguimos com um projeto móvel que, em um único aplicativo, une jovens evangélicos e os mantém engajados em eventos ou encontros, além de proporcionar uma experiência dinâmica, com atividades e muito propósito na palma da mão.
                  </p>
                  <p className="body-paragraph">
                    Em uma jornada de aprendizado e desenvolvimento pessoal, construímos talentos e inovamos a cada dia.
                  </p>
                  <p className="body-paragraph">
                    Nosso objetivo é continuar inovando e levando as pessoas ao conhecimento. Em cada projeto, desenvolvemos não apenas sistemas, mas também pessoas que aprendem e ganham experiências valiosas para a vida.
                  </p>
                </>
              )}
              <button className="read-more-button" onClick={handleReadMore}>
                {showMore ? 'Ler Menos' : 'Ler Mais'}
              </button>
            </div>
          </div>
        </div>
        <div className="right-column">
          <img src={lateral1} className="body-image" alt="outra imagem" />
        </div>
      </main>


      <footer className="App-footer">
        <div className="footer-links">
          <p>© 2024 Desbravalink. Todos os direitos reservados.</p>
          <Link className="link-footer" to="/politica">Política</Link>
          <Link className="link-footer" to="/terms_of_use">Termo de Uso</Link>
          <Link className="link-footer" to="/produtos">Produtos</Link>
          <Link className="link-footer" to="/contato">Contato</Link>
          {/* <Link className="link-footer" to="/sobre">Sobre</Link> */}
        </div>
      </footer>
    </div>
  );
}

export default Home;
