import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import '../styles/contato.css';
import logoQ from '../assets/imgs/logo-fundo-b.png';
import lateral1 from '../assets/imgs/lateral1.png';

function Contato() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Contato">
      <header className="Contato-header">
        <Link to="/">
          <img src={logoQ} className="Contato-logo" alt="logo" />
        </Link>
        <div className="Contato-header-content">
          <h1 className="Contato-title"><span className="Span-contato">Contato</span> Desbravalink</h1>
          <img src={lateral1} className="Img-lateral" alt="imagem lateral" />
        </div>
        <a href="#" target="_blank" rel="noopener noreferrer" className="Button-left">
          <FontAwesomeIcon icon={faDiscord} /> 
          Discord
        </a>
      </header>

      <main className="Contato-main">
        <div className="Contato-content">
          <div className="Text-section">
            <h1 className="H1-destaque">Entre em contato conosco</h1>
            <p className="P-destaque">
              Email: suportedesbravalink@gmail.com<br/>
              Telefone: (84) 99634-1559<br/>
              Endereço: Pedro Caetano de Macedo, 2501, Macaíba - RN, Brasil<br/>
              Horário de Atendimento: Segunda a Sexta, das 8h às 16h
            </p>
          </div>
        </div>
      </main>

      <footer className="App-footer">
        <div className="footer-links">
          <p>© 2024 Desbravalink. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}

export default Contato;
